import React from "react";
import Layout from "../../components/layout/layout";
import { StaticImage } from "gatsby-plugin-image";

import SEO from "../../components/seo";

export default function Message() {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="Founding President of Singapore Women's Association - Seow Peck Leng"
          description="SWA's Founder, the late Mrs Seow Peck Leng (neé Chua Seng Kim
                  17 September 1911 - 13 April 2007) was a politician, educator
                  and advocate for women's rights. She was one of the first
                  women members of parliament in Singapore and the only woman of
                  an opposition party at the time."
        />
        <div className="relative py-16 overflow-hidden ">
          <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
            <div
              className="relative h-full mx-auto text-lg max-w-prose"
              aria-hidden="true"
            >
              <svg
                className="absolute transform translate-x-32 top-12 left-full"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-swa-4"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                />
              </svg>
              <svg
                className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-swa-3"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                />
              </svg>
              <svg
                className="absolute transform translate-x-32 bottom-12 left-full"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="d3eb07ae-5182-43e6-857d-35c643af9034"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-swa-2"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                />
              </svg>
            </div>
          </div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="mx-auto text-lg max-w-prose">
              <h1>
                <span className="block mt-2 text-4xl font-extrabold leading-8 text-center text-swa-1 sm:text-4xl">
                  Tribute to Mrs Seow Peck Leng
                </span>
                <span className="block text-lg font-semibold tracking-wide text-center text-gray-500 ">
                  Founding President of Singapore Women's Association
                </span>
              </h1>
            </div>
            <div className="mx-auto mt-6 prose prose-lg text-gray-500 prose-indigo">
              <div className="md:space-x-5 md:content-center md:justify-center md:flex md:flex-row">
                <StaticImage
                  src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636777575/homePage/ledgbtsr6vebnvgs60hk.jpg"
                  alt="Seow Peck Leng"
                />
                <p className="">
                  SWA's Founder, the late Mrs Seow Peck Leng (neé Chua Seng Kim
                  17 September 1911 - 13 April 2007) was a politician, educator
                  and advocate for women's rights. She was one of the first
                  women members of parliament in Singapore and the only woman of
                  an opposition party at the time.
                  <br />
                  <br />
                  Mrs Seow first went to Singapore Chinese Girls' School then
                  Raffles Girls' School and started teaching in 1930. She was an
                  active member with the Singapore Teacher's Union.
                </p>
              </div>

              <p></p>
              <p>
                A confident and caring woman, she went on to join the Singapore
                Council of Women led by Shirin Fozdar in 1952. She became the
                principal of the Cantonment School in 1954 the same year, she
                founded the Siglap Girls' Club, to help underprivileged girls in
                Singapore. And in 1957, she expanded the mission of the club and
                renamed the group to Singapore Women's Association (Persatuan
                Wanita Singapura). She was President until 1991.
              </p>
              <p>
                Mrs Seow was voted into Parliament as a candidate of Singapore
                People's Alliance (SPA) in 1959. She was the only opposition MP
                woman in Parliament at the time. Mrs Seow was able to work with
                members of the other party, the People's Action Party (PAP) to
                work towards gender equality. She was involved with helping to
                pass the Women's Charter, and the 1962 provision for equal pay
                for equal work in the civil service sector. She lost her seat in
                1963 and retired from politics in 1965.
              </p>
              <p>
                Mrs Seow Peck Leng then focus her efforts on charity work and
                devoted herself tirelessly to improving the lives of people –
                especially women. She cared deeply for women in the lower strata
                of society. She felt that the upper strata, who were aware of
                their rights and privileges, should be educated to become
                responsible citizens. During her time as an MP, she advocated
                the organization of centers that could advise the poor on where
                and how they could obtain help, as well as the formation of more
                youth clubs. She also campaigned for improving literacy rates
                amongst women, as well as for men to take a more active role in
                housework.
              </p>
              <p>
                In 1975, she then focus her efforts on charity work. Mrs Seow
                promoted Miss Singapore World beauty pageant shows for charity
                and encourage women to help the less fortunate. In 1977, she was
                given a plaque of Life Patronage of the International Variety
                Club for raising the most charity funds. The Ministry of Social
                Affairs awarded her a Certificate of Appreciation for "valuable
                services rendered to others. And in 1977, Mrs Seow added another
                title, Miss Singapore International to beauty pageant.
              </p>
              <p>
                Mrs Seow passed away on 13th April 2007. The strides that she
                made in fighting for the rights of women and people will forever
                live on.
              </p>
              <p>
                Mrs Seow had left us a legacy. In 2005, she was honored on the
                Wall of Fame created by the Singapore Council of Women's
                Organisations (SCWO). In 2014, she was added to the Singapore
                Memory Project and was inducted into the Singapore Women's Hall
                of Fame.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
